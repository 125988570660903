import React, { useRef } from "react";
import SectionHeader from "../components/sectionHeader";
import Layout from "../components/layout";
import downArrow from "../assets/icons/icon-down-arrow.png";
import enrolMaintain from "../assets/icons/icon-maintain.png";

import "../components/enrol/enrol.scss";
import { Fade } from "react-reveal";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import AnalyticsFeatures from "../components/features/AnalyticsFeatures";

import FeaturesBackgroundBanner from "../components/features/FeaturesBackgroundBanner";

import "../components/features/moreFeaturesLinksfeodaflow.scss";
import { Nav } from "react-bootstrap";

import BudgetFeatures from "../components/features/BudgetFeatures";
import IntegrateFeatures from "../components/features/IntegrateFeatures";

function Analytics() {
  const AnalyticsPageData = useStaticQuery(graphql`
    query {
      allStrapiAnalyticsPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
      allStrapiFeodafusionPage {
        nodes {
          PageHeader {
            Image {
              publicURL
            }
            Title
            TitleHighlight
            Content
            id
          }
        }
      }
      allStrapiBudgetPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
      allStrapiIntegratePage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Title
            id
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
              id
            }
          }

          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
            id
          }
          PageTitle
        }
      }
    }
  `);
  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log("Reached bottom");
        // navigate('/');
      }
    }
  };
  const pageData = AnalyticsPageData.allStrapiAnalyticsPage.nodes[0];
  const feodapageData = AnalyticsPageData.allStrapiFeodafusionPage.nodes[0];
  const integratepageData = AnalyticsPageData.allStrapiIntegratePage.nodes[0];
  const BudgetpageData = AnalyticsPageData.allStrapiBudgetPage.nodes[0];

  return (
    <Layout>
      <Helmet defer={false}>
        <title>{pageData.PageTitle}</title>
      </Helmet>
      {/* Newly added */}
      <Container>
        <Row className="enrol-hero">
          <Col md>
            <Fade left>
              <div>
                <SectionHeader
                  icon={enrolMaintain}
                  title={""}
                  highlight={""}
                  text={feodapageData.PageHeader[0].Text}
                />
                <h1>
                  {feodapageData.PageHeader[0].Title}
                  <strong>{feodapageData.PageHeader[0].TitleHighlight}</strong>
                </h1>
                <ReactMarkdown source={feodapageData.PageHeader[0].Content} />
                <img src={downArrow} width="60" alt="icon"></img>
              </div>
            </Fade>
          </Col>

          <Col md className="hero-image">
            <img
              src={feodapageData.PageHeader[0].Image.publicURL}
              className="drop-shadow xyz-in"
              alt="process"
              xyz="fade right stagger delay-4 duration-10"
              width="600"
            ></img>
          </Col>
        </Row>
      </Container>
      {/* Newly added */}

      <section id="analyticssection">
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolMaintain}
                    title={pageData.PageHeader[0].Title}
                    highlight={pageData.PageHeader[0].TitleHighlight}
                    text={pageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown source={pageData.PageHeader[0].Content} />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={pageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <AnalyticsFeatures data={pageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="budgetsection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    //icon={enrolMaintain}
                    title={BudgetpageData.PageHeader[0].Title}
                    highlight={BudgetpageData.PageHeader[0].TitleHighlight}
                    text={BudgetpageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown
                    source={BudgetpageData.PageHeader[0].Content}
                  />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={BudgetpageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <BudgetFeatures data={BudgetpageData.KeyFeatures[0]} />
        </Container>
      </section>

      <section id="integratesection" STYLE={"display:none"}>
        <Container
          className="enrol"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          <Row className="enrol-hero">
            <Col md>
              <Fade left>
                <div>
                  <SectionHeader
                    // icon={enrolMaintain}
                    title={integratepageData.PageHeader[0].Title}
                    highlight={integratepageData.PageHeader[0].TitleHighlight}
                    text={integratepageData.PageHeader[0].Text}
                  />

                  <ReactMarkdown
                    source={integratepageData.PageHeader[0].Content}
                  />
                </div>
              </Fade>
            </Col>
            <Col md className="hero-image">
              <img
                src={integratepageData.PageHeader[0].Image.publicURL}
                className="drop-shadow xyz-in"
                alt="process"
                xyz="fade right stagger delay-4 duration-10"
                width="600"
              ></img>
            </Col>
          </Row>
          <IntegrateFeatures data={integratepageData.KeyFeatures[0]} />
        </Container>
      </section>
      <section className="feature-links">
        <Nav>
          <button
            id="analyticsbtn"
            onClick={Togglefuncanalytics}
            className="button active1"
          >
            Analytics
          </button>
          <button id="budgetbtn" onClick={Togglefuncbudget} className="button">
            Budget
          </button>
          <button
            id="integratebtn"
            onClick={Togglefuncintegrate}
            className="button"
          >
            Integrate
          </button>
        </Nav>
      </section>

      <FeaturesBackgroundBanner />
    </Layout>
  );
}
function Togglefuncanalytics() {
  if (
    document.getElementById("analyticssection") &&
    document.getElementById("budgetsection") &&
    document.getElementById("integratesection")
  ) {
    const targetDiv1 = document.getElementById("analyticssection");
    const targetDiv2 = document.getElementById("budgetsection");
    const targetDiv3 = document.getElementById("integratesection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "block";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("analyticsbtn").classList.add("active1");
  }
}
function Togglefuncbudget() {
  if (
    document.getElementById("analyticssection") &&
    document.getElementById("budgetsection") &&
    document.getElementById("integratesection")
  ) {
    const targetDiv1 = document.getElementById("analyticssection");
    const targetDiv2 = document.getElementById("budgetsection");
    const targetDiv3 = document.getElementById("integratesection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "block";
    targetDiv3.style.display = "none";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("budgetbtn").classList.add("active1");
  }
}

function Togglefuncintegrate() {
  if (
    document.getElementById("analyticssection") &&
    document.getElementById("budgetsection") &&
    document.getElementById("integratesection")
  ) {
    const targetDiv1 = document.getElementById("analyticssection");
    const targetDiv2 = document.getElementById("budgetsection");
    const targetDiv3 = document.getElementById("integratesection");
    //const btn = document.getElementById("toggle");

    targetDiv1.style.display = "none";
    targetDiv2.style.display = "none";
    targetDiv3.style.display = "block";
    var currentbtn;
    currentbtn = document.getElementsByClassName("button active1");
    currentbtn[0].classList.remove("active1");
    document.getElementById("integratebtn").classList.add("active1");
  }
}
export default Analytics;
